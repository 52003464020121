import http from "../http/httpService";

/* It's a class that contains methods that make HTTP requests to the server */
class PagesService {

  /**
   * It takes a createUserInput object, sends it to the server, and returns the result
   * @param createUserInput - The input object that will be sent to the server.
   * @returns The result of the post request.
   */
  async create(createUserInput) {
    let result = await http.post(
      "api/services/app/PagesCommand/Create",
      createUserInput
    );
    return result.data.result;
  }

  async delete(entityDto) {
    let result = await http.delete("api/services/app/PagesCommand/Delete", {
      params: entityDto,
    });
    return result.data;
  }

  async get(entityDto) {
    let result = await http.get("api/services/app/PagesQuery/GetById", {
      params: entityDto,
    });
    return result.data.result;
  }

  async getAll(pagedFilterAndSortedRequest) {
    let result = await http.get("api/services/app/PagesQuery/GetAll", {
      params: pagedFilterAndSortedRequest,
    });
    return result.data.result;
  }

  async update(updateRoleInput) {
    let result = await http.put(
      "api/services/app/PagesCommand/Update",
      updateRoleInput
    );
    return result.data.result;
  }
}

export default new PagesService();
