import http from '../http/httpService';

class ActivitiesService {
    async getAll() {
        const result = await http.get('api/services/app/ReferenceData/GetActivities');
        return result.data.result;
    }
}

export default new ActivitiesService();
