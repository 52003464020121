import React, { useEffect, useState } from 'react';
import CrudTable from '../../components/crudTable/CrudTable';
import pagesService from '../../services/pages/pagesService';
import { useNavigate } from 'react-router-dom';

const Pages = () => {
    const [pages, setPages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 5,
        page: 1,
        sortField: null,
        sortOrder: null,
        totalRecords: 0
    });
    const [globalFilter, setGlobalFilter] = useState('');
    const navigate = useNavigate();
    let timer;

    useEffect(() => {
        getAllPages();
    }, [lazyParams.first, lazyParams.rows, globalFilter]);

    const onGlobalFilterChange = (value) => {
        clearTimeout(timer);

        timer = setTimeout(() => {
            setGlobalFilter(value);
        }, 300);
    };

    const getAllPages = () => {
        setLoading(true);
        pagesService
            .getAll({
                SkipCount: lazyParams.first,
                MaxResultCount: lazyParams.rows,
                Keyword: globalFilter
            })
            .then((pages) => {
                setPages(pages.items);
                setLazyParams({ ...lazyParams, totalRecords: pages.totalCount });
                setLoading(false);
            });
    };

    const onEditSubmit = (data) => {
        pagesService.update(data).then((res) => {
            getAllPages();
            setLoading(false);
        });
    };

    const onCreateSubmit = (data) => {
        pagesService
            .create(data)
            .then((res) => {
                getAllPages();
                setLoading(false);
            })
            .catch((i) => {
                setLoading(false);
            });
    };

    const onDelete = (data) => {
        pagesService
            .delete({
                id: data.id
            })
            .then((res) => {
                getAllPages();
                setLoading(false);
            });
    };

    const getSinglePageContent = (id) => {
        navigate('/pages/' + id);

        return Promise.resolve();
    };

    const fieldsToShow = [
        {
            header: 'Key',
            name: 'key'
        },
        {
            header: 'Status',
            name: 'status'
        }
    ];

    const fieldsToEdit = [
        {
            name: 'key',
            title: 'Key',
            type: 'string'
        },
        {
            name: 'content',
            title: 'Content',
            type: 'textarea'
        }
    ];

    const fieldsToCreate = [
        {
            name: 'key',
            title: 'Key',
            type: 'string'
        }
    ];

    return (
        <div>
            <CrudTable
                data={pages}
                fieldsToShow={fieldsToShow}
                fieldsToEdit={fieldsToEdit}
                fieldsToCreate={fieldsToCreate}
                loading={loading}
                onEditSubmit={onEditSubmit}
                onCreateSubmit={onCreateSubmit}
                onDelete={onDelete}
                headerTitle="Manage Pages"
                setLoading={setLoading}
                lazy={true}
                lazyParams={lazyParams}
                setLazyParams={setLazyParams}
                globalFilter={globalFilter}
                setGlobalFilter={onGlobalFilterChange}
                primaryKey="id"
                getSingleEntity={getSinglePageContent}
            />
        </div>
    );
};

export default Pages;
