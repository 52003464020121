import http from '../http/httpService';

class SermonsService {
    async create(sermon) {
        const result = await http.post('api/services/app/SermonsCommand/Create', sermon);
        return result.data.result;
    }

    async update(sermon) {
        const result = await http.put('api/services/app/SermonsCommand/Update', sermon);
        return result.data.result;
    }

    async delete(sermon) {
        const result = await http.delete('api/services/app/SermonsCommand/Delete', sermon);
        return result.data.result;
    }
}

export default new SermonsService();
