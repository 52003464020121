import axios from 'axios';
import AppConsts from '../../utils/appconst';

const qs = require('qs');

let abp = window.abp;

const http = axios.create({
    baseURL: AppConsts.remoteServiceBaseUrl,
    timeout: 100000,
    paramsSerializer: function (params) {
        return qs.stringify(params, {
            encode: false
        });
    }
});

http.interceptors.request.use(
    function (config) {
        if (!!abp.auth.getToken()) {
            config.headers.common['Authorization'] = 'Bearer ' + abp.auth.getToken();
        }

        config.headers.common['.AspNetCore.Culture'] = abp.utils.getCookieValue('Abp.Localization.CultureName');
        config.headers.common['Abp.TenantId'] = abp.multiTenancy.getTenantIdCookie();

        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

http.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (!!error.response && !!error.response.data.error && !!error.response.data.error.message && error.response.data.error.details) {
            // show error
        } else if (!!error.response && !!error.response.data.error && !!error.response.data.error.message) {
            // login failed error
        } else if (!error.response) {
            // unknown error
        }

        return Promise.reject(error);
    }
);

const getFormData = (dataInput) => {
    const formData = new FormData();

    for (const prop in dataInput) {
        if (!dataInput[prop]) {
            continue;
        } else if (prop.toLocaleLowerCase().includes('image')) {
            formData.append(`file${prop}`, dataInput[prop]);
        } else {
            if (prop.includes('partnerSupportedPayment')) {
                for (let arrayItem of dataInput[prop]) {
                    formData.append(prop, arrayItem);
                }
            } else {
                formData.append(prop, dataInput[prop]);
            }
        }
    }
    return formData;
};

export { getFormData };

export default http;
