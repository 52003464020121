import React from 'react';
import { useNotifications } from '../../hooks/notifications/notificationsHook';
import styled from 'styled-components';
import { ProgressBar } from 'primereact/progressbar';
import { getFileName } from '../../services/files/filesService';

const AppRightPanel = (props) => {
    const { files } = useNotifications();
    return (
        <div className="layout-rightpanel" onClick={props.onRightPanelClick}>
            <div className="rightpanel-wrapper">
                <div className="rightpanel-section tasks-section">
                    <div className="section-header">
                        <h6>Notifications</h6>
                    </div>
                    <ul>
                        {files.map((file) => (
                            <FileNotification key={file.fileId}>
                                <img alt="" src={file.image} />
                                <div className="info">
                                    <h6 className="m-0">{getFileName(file.fileName)}</h6>
                                    <span>{file?.action === 'upload' ? 'File is uploading...' : 'File is downloading...'}</span>
                                    <ProgressBar mode="indeterminate" style={{ height: '6px', width: '100%' }}></ProgressBar>
                                </div>
                            </FileNotification>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

const FileNotification = styled.li`
    gap: 10px;
    display: grid !important;
    grid-template-columns: 1fr 5fr;
    justify-content: flex-start !important;

    .info {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 6px;
    }

    img {
        width: 100px;
        height: 80px;
        object-fit: contain;
        border-radius: 5px;
    }
`;

export default AppRightPanel;
