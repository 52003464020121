import { Button, Calendar, Checkbox, InputText } from 'primereact';
import { useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { ReferenceDataContext } from '../../../components/configuration/InitialConfigurationWrapper';
import meetingsService from '../../../services/meetings/meetingsService';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

const getNow = () => {
    const now = new Date();
    let minutes = now.getMinutes();
    minutes = minutes - (minutes % 10);
    now.setMinutes(minutes, 0, 0);
    return now;
};

const implementedActivities = ['Sermon', 'Multimedia'];

export const MeetingCreate = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { activities } = useContext(ReferenceDataContext);
    const {
        handleSubmit,
        register,
        reset,
        control,
        formState: { errors }
    } = useForm({
        defaultValues: {
            name: '',
            startTime: getNow(),
            meetingActivities: []
        }
    });

    const onSubmit = async (data) => {
        data.meetingActivities = data.meetingActivities.filter((i) => i.activity !== false);

        reset({});
        await queryClient.refetchQueries(['latestMeeting']);
        const id = await meetingsService.create(data).catch((e) => {});
        navigate(`/meetings/${id}/details`);
    };
    return (
        <div className="card">
            <Form onSubmit={handleSubmit(onSubmit)} className="card-body">
                <Input>
                    <label>
                        Name <ErrorMessage>{errors.name?.message}</ErrorMessage>
                    </label>
                    <InputText {...register('name', { required: 'is required' })} />
                </Input>
                <Input>
                    <label>
                        Start Time <ErrorMessage>{errors.startTime?.message}</ErrorMessage>
                    </label>
                    <Controller
                        rules={{
                            required: 'is required'
                        }}
                        name="startTime"
                        control={control}
                        render={({ field }) => <Calendar value={new Date(field.value)} showTime stepMinute={10} onChange={(e) => field.onChange(e.value)} />}
                    />
                </Input>
                <Input>
                    <label>Activities</label>

                    {activities.map(
                        (activity, idx) =>
                            implementedActivities.includes(activity.activity) && (
                                <CheckboxItem key={activity.activity}>
                                    <Controller
                                        defaultValue={activity.activity}
                                        name={`meetingActivities.${idx}.activity`}
                                        control={control}
                                        render={({ field }) => <Checkbox checked={field.value === activity.activity} onChange={(e) => field.onChange(e.checked ? activity.activity : false)} />}
                                    />
                                    <label>{activity.activity}</label>
                                </CheckboxItem>
                            )
                    )}
                </Input>
                <Button type="submit" label="Submit" />
            </Form>
        </div>
    );
};

const Input = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 300px;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const ErrorMessage = styled.span`
    color: red;
`;

const CheckboxItem = styled.div`
    display: flex;
    gap: 10px;
`;
