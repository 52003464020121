import React from "react";
import { Navigate } from "react-router-dom";
import authenticationService from "../../services/authentication/authenticationService";
import { isGranted } from "../../utils/abpUtility";

const ProtectedRoute = ({ children, permission }) => {
  let authService = authenticationService;
  let granted = permission ? isGranted(permission) : true;

  if (!authService.isAuthenticated() || !granted) {
    return <Navigate to={"/access"} replace />;
  }

  return children;
};

export default ProtectedRoute;
