import React, { useContext, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import authenticationService from '../services/authentication/authenticationService';
import { GoogleLogin } from '@react-oauth/google';
import { AuthContext } from '../components/configuration/InitialConfigurationWrapper';

export const Login = () => {
    const [globalError, setGlobalError] = useState(null);
    const { authProviders } = useContext(AuthContext);
    const { register, handleSubmit, control } = useForm({
        defaultValues: {
            userNameOrEmailAddress: '',
            password: ''
        }
    });
    const navigate = useNavigate();

    const onLogin = (data) => {
        data.rememberMe = true;

        authenticationService
            .login(data)
            .then(() => {
                window.location.replace(process.env.PUBLIC_URL);
            })
            .catch(() => {
                setGlobalError('Wrong email or password');
            });
    };

    const onExternalLogin = (data) => {
        data.rememberMe = true;
        authenticationService
            .externalAuthentiate(data)
            .then(() => {
                window.location.replace(process.env.PUBLIC_URL);
            })
            .catch(() => {
                setGlobalError('Please wait for activation');
            });
    };

    return (
        <div className="login-body">
            <div className="login-wrapper">
                <form className="login-panel" onSubmit={handleSubmit(onLogin)}>
                    <button onClick={() => navigate('/')} className="logo p-link">
                        <img src="assets/layout/images/blue-only-logo.svg" alt="saint-john" />
                    </button>

                    {globalError && <div className="login_errorPanel">{globalError}</div>}

                    <InputText id="email" placeholder="Email" {...register('userNameOrEmailAddress')} />
                    <Controller name="password" control={control} render={({ field }) => <Password id="password" placeholder="Password" {...field} />} />
                    <Button label="LOGIN" type="submit"></Button>
                    {authProviders?.map((externalProvider) => {
                        return externalProvider.name === 'Google' ? (
                            <GoogleLogin
                                key={externalProvider.name}
                                onSuccess={(credentialResponse) => {
                                    const externalAuthenticateModel = {
                                        authProvider: externalProvider.name,
                                        providerKey: credentialResponse.clientId,
                                        providerAccessCode: credentialResponse.credential
                                    };
                                    onExternalLogin(externalAuthenticateModel);
                                }}
                                onError={(error) => {
                                    console.error(error);
                                }}
                            />
                        ) : null;
                    })}
                </form>
            </div>
        </div>
    );
};
