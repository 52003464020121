import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react';
import styled from 'styled-components';
import { L } from '../../../../utils/abpUtility';
import bibleService from '../../../../services/bible/bibleService';
import { useQuery } from 'react-query';
import { Button } from 'primereact/button';

export const RenderVerseChapterName = ({ text }) => {
    const options = text.split('.');

    if (options.length < 3) return;

    return `${L(options[0])} ${options[1]}:${options[2]}`;
};

export const BibleDialog = forwardRef(function BibleDialog(props, ref) {
    const { onSubmit, selectedVerses } = props;
    const [visible, setVisible] = useState(false);
    const { data: bibleBooksResponse } = useQuery({
        queryKey: ['books'],
        queryFn: () => bibleService.getBooks()
    });

    const books = useMemo(() => {
        let bibleBooks = bibleBooksResponse ? Object.keys(bibleBooksResponse) : [];
        return bibleBooks.map((i) => {
            return {
                label: L(i),
                value: i
            };
        });
    }, [bibleBooksResponse]);

    const [bibleData, setBibleData] = useState({
        book: undefined,
        chapter: undefined,
        verse: undefined,
        chapterText: selectedVerses || []
    });

    const [bibleOptions, setBibleOptions] = useState({
        chapters: undefined,
        verses: []
    });

    useImperativeHandle(
        ref,
        () => {
            return {
                openDialog() {
                    setVisible(true);
                },
                closeDialog() {
                    setVisible(false);
                }
            };
        },
        []
    );

    const onBookChange = async (book) => {
        setBibleData({
            ...bibleData,
            book,
            chapter: undefined,
            verse: undefined
        });

        const chaptersData = await bibleService.getChapters(book);
        const chapters = Object.keys(chaptersData).map((chapter) => {
            const chapterLocalizedName = `${L(book)} ${chapter.split('.')?.[1]}`;
            return { label: chapterLocalizedName, value: chapter };
        });

        setBibleOptions({
            ...bibleOptions,
            chapters
        });
    };

    const onChapterChange = async (chapter) => {
        setBibleData({
            ...bibleData,
            chapter
        });

        let versesData = await bibleService.getVerses(chapter);
        const verses = Object.values(versesData).map((verse, id) => {
            return {
                id: id + 1,
                text: verse
            };
        });

        setBibleOptions({ ...bibleOptions, verses: verses });
    };
    const handleVerseClicked = (verseId) => {
        if (bibleData.chapterText.find((i) => i.name === getVerseName(verseId))) {
            const verses = bibleData.chapterText.filter((i) => i.name !== getVerseName(verseId));
            setBibleData({
                ...bibleData,
                chapterText: verses
            });
        } else {
            const content = bibleOptions.verses.find((i) => i.id === verseId);
            setBibleData({
                ...bibleData,
                chapterText: [...bibleData.chapterText, { name: getVerseName(verseId), content: content.text }]
            });
        }
    };

    const getVerseName = useCallback(
        (id) => {
            return `${bibleData.chapter}.${id}`;
        },
        [bibleData]
    );

    const onSave = () => {
        onSubmit(bibleData.chapterText);
    };

    const renderFooter = () => {
        return (
            <div className="pt-3">
                <Button label="Save" onClick={onSave} />
            </div>
        );
    };

    const handleVerseRemoval = (name) => {
        const verses = bibleData.chapterText.filter((i) => i.name !== name);
        setBibleData({
            ...bibleData,
            chapterText: verses
        });
    };

    return (
        <Dialog
            visible={visible}
            header="Select Bible verses"
            onHide={() => setVisible(false)}
            style={{
                width: '60vw'
            }}
            draggable={false}
            footer={renderFooter}
        >
            <ul>
                {bibleData.chapterText.map((i) => (
                    <li style={{ fontWeight: '800', display: 'flex', gap: 5, alignItems: 'center', fontSize: '16px' }} key={i.name}>
                        <RenderVerseChapterName text={i.name} />
                        <i
                            className="fa fa-xmark"
                            onClick={() => handleVerseRemoval(i.name)}
                            style={{
                                fontSize: '20px',
                                color: 'red',
                                cursor: 'pointer'
                            }}
                        />
                    </li>
                ))}
            </ul>
            <SermonBibleContainer>
                <Dropdown disabled={!books} placeholder="Select a book" options={books} onChange={(e) => onBookChange(e.target.value)} value={bibleData.book} />
                <Dropdown disabled={!bibleOptions.chapters} placeholder="Select a chapter" options={bibleOptions.chapters} onChange={(e) => onChapterChange(e.target.value)} value={bibleData.chapter} />
            </SermonBibleContainer>
            <VersesContainer>
                {bibleOptions.verses.map((verse) => (
                    <Verse
                        key={verse.id}
                        onClick={() => handleVerseClicked(verse.id)}
                        style={{
                            textDecoration: bibleData.chapterText.find((i) => i.name === getVerseName(verse.id)) ? 'underline' : undefined
                        }}
                    >
                        <VerseIdentifier>{verse.id}</VerseIdentifier>
                        {verse.text}
                    </Verse>
                ))}
            </VersesContainer>
        </Dialog>
    );
});

const VerseIdentifier = styled.span`
    font-weight: 300;
    font-size: 0.9rem;
    margin-top: 2px;
`;

const VersesContainer = styled.div`
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const Verse = styled.div`
    display: flex;
    gap: 6px;
    font-size: 1.2rem;
    cursor: pointer;
`;

const SermonBibleContainer = styled.div`
    display: flex;
    gap: 5px;
`;
