import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import ConfigurationWrapper from './components/configuration/InitialConfigurationWrapper';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { PrimeReactProvider } from 'primereact/api';

const root = ReactDOM.createRoot(document.getElementById('root'));

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false
        }
    }
});

root.render(
    <React.StrictMode>
        <PrimeReactProvider>
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <ConfigurationWrapper />
                </BrowserRouter>
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </PrimeReactProvider>
    </React.StrictMode>
);

reportWebVitals();
