import React, { useEffect, useState } from "react";
import CrudTable from "../../components/crudTable/CrudTable";
import rolesService from "../../services/roles/rolesService";
import { ListBox } from "primereact/listbox";
import { Controller } from "react-hook-form";

const Roles = () => {
  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 5,
    page: 1,
    sortField: null,
    sortOrder: null,
    totalRecords: 0,
  });
  const [globalFilter, setGlobalFilter] = useState("");
  let timer;

  useEffect(() => {
    getAllRoles();
  }, [lazyParams.first, lazyParams.rows, globalFilter]);

  const onGlobalFilterChange = (value) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      setGlobalFilter(value);
    }, 300);
  };

  const getAllRoles = () => {
    setLoading(true);
    rolesService
      .getAll({
        SkipCount: lazyParams.first,
        MaxResultCount: lazyParams.rows,
        Keyword: globalFilter,
      })
      .then((roles) => {
        setRoles(roles.items);
        setLazyParams({ ...lazyParams, totalRecords: roles.totalCount });
        rolesService.getAllPermissions().then((permissions) => {
          setPermissions(permissions.items);
          setLoading(false);
        });
      });
  };

  const onEditSubmit = (data) => {
    rolesService.update(data).then((res) => {
      getAllRoles();
      setLoading(false);
    });
  };

  const onCreateSubmit = (data) => {
    rolesService.create(data).then((res) => {
      getAllRoles();
      setLoading(false);
    });
  };

  const onDelete = (data) => {
    rolesService
      .delete({
        id: data.id,
      })
      .then((res) => {
        getAllRoles();
        setLoading(false);
      });
  };

  const permissionsEditBody = (data) => {
    return (
      <Controller
        name="grantedPermissions"
        control={data.control}
        render={({ field }) => (
          <ListBox
            options={permissions}
            multiple={true}
            optionLabel="name"
            optionValue="name"
            filter
            {...field}
          />
        )}
      ></Controller>
    );
  };

  const fieldsToShow = [
    {
      header: "Name",
      name: "name",
    },
    {
      header: "Display Name",
      name: "displayName",
    },
    {
      header: "Normalized Name",
      name: "normalizedName",
    },
  ];

  const fieldsToEdit = [
    {
      name: "name",
      title: "Name",
      type: "string",
    },
    {
      name: "displayName",
      title: "Display Name",
      type: "string",
    },
    {
      name: "description",
      title: "Description",
      type: "textarea",
    },
    {
      name: "grantedPermissions",
      title: "Granted Permission",
      body: permissionsEditBody,
    },
  ];

  const fieldsToCreate = [
    {
      name: "name",
      title: "Name",
      type: "string",
    },
    {
      name: "displayName",
      title: "Display Name",
      type: "string",
    },
    {
      name: "description",
      title: "Description",
      type: "textarea",
    },
    {
      name: "grantedPermissions",
      title: "Granted Permission",
      body: permissionsEditBody,
    },
  ];

  const getSingleRoles = async (id) => {
    const roleData = await rolesService.get({ id });
    return roleData;
  };

  return (
    <div>
      <CrudTable
        data={roles}
        fieldsToShow={fieldsToShow}
        fieldsToEdit={fieldsToEdit}
        fieldsToCreate={fieldsToCreate}
        loading={loading}
        onEditSubmit={onEditSubmit}
        onCreateSubmit={onCreateSubmit}
        onDelete={onDelete}
        headerTitle="Manage Roles"
        setLoading={setLoading}
        lazy={true}
        lazyParams={lazyParams}
        setLazyParams={setLazyParams}
        globalFilter={globalFilter}
        setGlobalFilter={onGlobalFilterChange}
        primaryKey={"id"}
        getSingleEntity={getSingleRoles}
      />
    </div>
  );
};

export default Roles;
