import { Button, InputText } from 'primereact';
import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import CustomEditor from '../../components/Editor/Editor';
import pagesService from '../../services/pages/pagesService';
import { L } from '../../utils/abpUtility';
import './edit.scss';

const EditPage = () => {
    const { id } = useParams();
    const editorRef = useRef(null);
    const [data, setData] = useState(null);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({
        defaultValues: {
            key: ''
        }
    });

    useEffect(() => {
        (async () => {
            const data = await pagesService.get({ id });
            if (data) {
                setLoading(false);
                reset(data);
                setData(data);
            }
        })();
    }, [id, reset]);

    const onSubmit = (data) => {
        setLoading(true);
        pagesService
            .update({
                id: id,
                key: data.key,
                content: editorRef.current.getContent()
            })
            .then(() => {
                setLoading(false);
                navigate('/pages');
            })
            .catch((e) => {
                alert('error with the request');
            });
    };

    return (
        <div className="card edit-card">
            <form onSubmit={handleSubmit(onSubmit)} className="container">
                <div className="field">
                    <label htmlFor="key" className="block">
                        Key
                    </label>
                    <InputText
                        id="key"
                        aria-describedby="key-help"
                        style={{ width: '100%' }}
                        className={`${errors['key']?.message && 'p-invalid'} block`}
                        {...register('key', {
                            pattern: { value: /^[a-z-]+$/, message: L('PageKeyInvalid') },
                            required: L('PageKeyRequired')
                        })}
                    />
                    {errors['key'] && (
                        <small id="key-help" className="p-error block">
                            {errors['key']?.message}
                        </small>
                    )}
                </div>

                <div className="field">
                    <label>Content:</label>
                    <CustomEditor initialValue={data?.content} editorRef={editorRef} />
                </div>
                <Button type="submit" label="Save Changes" />
            </form>
        </div>
    );
};

export default EditPage;
