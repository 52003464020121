import http from '../http/httpService';

class MeetingService {
    async create(data) {
        const result = await http.post('api/services/app/Meetings/Create', data);
        return result.data.result;
    }

    async getCurrentMeeting() {
        const result = await http.get('api/services/app/Meetings/GetCurrentMeeting');
        return result.data.result;
    }

    async get(data) {
        const result = await http.get('api/services/app/Meetings/GetById', {
            params: data
        });
        return result.data.result;
    }

    async getAll(data) {
        const result = await http.get('api/services/app/Meetings/GetAll', {
            params: data
        });
        return result.data.result;
    }

    async delete(data) {
        const result = await http.delete('api/services/app/Meetings/Delete', {
            params: data
        });
        return result.data.result;
    }
}

export default new MeetingService();
