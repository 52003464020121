import React, { useEffect, useState } from "react";
import CrudTable from "../../components/crudTable/CrudTable";
import { ListBox } from "primereact/listbox";
import usersService from "../../services/users/usersService";
import { Controller, useForm } from "react-hook-form";
import rolesService from "../../services/roles/rolesService";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import authenticationService from "../../services/authentication/authenticationService";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 5,
    page: 1,
    sortField: null,
    sortOrder: null,
    totalRecords: 0,
  });
  const [globalFilter, setGlobalFilter] = useState("");
  const [openRegisterDialog, setOpenRegisterDialog] = useState(false);
  const { register, handleSubmit, control } = useForm();
  let timer;

  useEffect(() => {
    getAllUsers();
  }, [lazyParams.first, lazyParams.rows, globalFilter]);

  const onGlobalFilterChange = (value) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      setGlobalFilter(value);
    }, 300);
  };

  const getAllUsers = () => {
    setLoading(true);
    usersService
      .getAll({
        SkipCount: lazyParams.first,
        MaxResultCount: lazyParams.rows,
        Keyword: globalFilter,
      })
      .then((users) => {
        setUsers(users.items);
        setLazyParams({ ...lazyParams, totalRecords: users.totalCount });
        rolesService.getAll().then((roles) => {
          setRoles(roles.items);
          setLoading(false);
        });
      });
  };

  const onEditSubmit = (data) => {
    usersService.update(data).then((res) => {
      getAllUsers();
      setLoading(false);
    });
  };

  const onCreateSubmit = (data) => {
    usersService.create(data).then((res) => {
      getAllUsers();
      setLoading(false);
    });
  };

  const onDelete = (data) => {
    usersService
      .delete({
        id: data.id,
      })
      .then((res) => {
        getAllUsers();
        setLoading(false);
      });
  };

  const rolesEditBody = (data) => {
    return (
      <Controller
        name={data.name}
        control={data.control}
        render={({ field }) => (
          <ListBox
            options={roles}
            multiple={true}
            optionLabel="normalizedName"
            optionValue="normalizedName"
            filter
            {...field}
          />
        )}
      ></Controller>
    );
  };
  const getSingleUser = async (id) => {
    const userData = await usersService.get({ id });
    return userData;
  };

  const fieldsToShow = [
    {
      header: "Name",
      name: "name",
    },
    {
      header: "User Name",
      name: "userName",
    },
    {
      header: "Surname",
      name: "surname",
    },
    {
      header: "Full Name",
      name: "fullName",
    },
    {
      header: "Email",
      name: "emailAddress",
    },
  ];

  const fieldsToEdit = [
    {
      name: "name",
      title: "Name",
      type: "string",
    },
    {
      name: "userName",
      title: "User Name",
      type: "string",
    },
    {
      name: "surname",
      title: "Surname",
      type: "string",
    },
    {
      name: "isActive",
      title: "Active",
      type: "bool",
    },
    {
      name: "emailAddress",
      title: "Email",
      type: "string",
    },
    {
      name: "roleNames",
      title: "Roles",
      body: rolesEditBody,
    },
  ];

  const fieldsToCreate = [
    {
      name: "name",
      title: "Name",
      type: "string",
    },
    {
      name: "userName",
      title: "User Name",
      type: "string",
    },
    {
      name: "surname",
      title: "Surname",
      type: "string",
    },
    {
      name: "isActive",
      title: "Active",
      type: "bool",
    },
    {
      name: "emailAddress",
      title: "Email",
      type: "string",
    },
    {
      name: "password",
      title: "Password",
      type: "password",
    },
    {
      name: "roleNames",
      title: "Roles",
      body: rolesEditBody,
    },
  ];

  const extendHeader = () => {
    return (
      <>
        <Button
          label="Register"
          icon="pi pi-user"
          className="p-button-help ml-2"
          onClick={() => setOpenRegisterDialog(true)}
        />
      </>
    );
  };

  const createRegistrationFooter = () => (
    <div className="align-items-center justify-content-end flex ">
      <Button label="Yes" icon="pi pi-check" type="submit" />
    </div>
  );

  const onSubmit = (data) => {
    authenticationService
      .registerUser({
        emailAddress: data.email,
        roleNames: data.roleNames,
      })
      .then((data) => {
        setOpenRegisterDialog(false);
        navigator.clipboard.writeText(data.url);
        getAllUsers();
      });
  };

  return (
    <div>
      <CrudTable
        data={users}
        fieldsToShow={fieldsToShow}
        fieldsToEdit={fieldsToEdit}
        fieldsToCreate={fieldsToCreate}
        loading={loading}
        onEditSubmit={onEditSubmit}
        onCreateSubmit={onCreateSubmit}
        onDelete={onDelete}
        headerTitle="Manage Users"
        setLoading={setLoading}
        lazy={true}
        lazyParams={lazyParams}
        setLazyParams={setLazyParams}
        globalFilter={globalFilter}
        setGlobalFilter={onGlobalFilterChange}
        primaryKey="id"
        getSingleEntity={getSingleUser}
        extendHeader={extendHeader}
      />

      <Dialog
        visible={openRegisterDialog}
        style={{ width: "450px" }}
        header="Confirm"
        modal
        onHide={() => setOpenRegisterDialog(false)}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            className="field"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <InputText
              {...register("email", { required: true, minLength: 5 })}
              type="email"
              placeholder="Email address"
            />
            <Controller
              name={"roleNames"}
              control={control}
              render={({ field }) => (
                <ListBox
                  options={roles}
                  multiple={true}
                  optionLabel="normalizedName"
                  optionValue="normalizedName"
                  filter
                  {...field}
                />
              )}
            ></Controller>
          </div>
          {createRegistrationFooter()}
        </form>
      </Dialog>
    </div>
  );
};

export default Users;
