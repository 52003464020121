import React, { useMemo, useState } from 'react';

export const NotificationContext = React.createContext(null);

export const NotificationWrapper = ({ children }) => {
    const [fileQueue, setFileQueue] = useState([]);

    const addFile = (file) => {
        setFileQueue((prev) => [...prev, file]);
    };

    const removeFile = (file) => {
        setFileQueue((prev) => prev.filter((i) => i !== file));
    };

    const contextObj = useMemo(() => {
        return {
            files: fileQueue,
            addFile: addFile,
            removeFile: removeFile
        };
    }, [fileQueue]);

    return <NotificationContext.Provider value={contextObj}>{children}</NotificationContext.Provider>;
};
