import styled from 'styled-components';
import { BibleDialog, RenderVerseChapterName } from './BibleDialog';
import { useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import sermonsService from '../../../../services/meetings/sermonsService';
import { useQueryClient } from 'react-query';
import { InputTextarea } from 'primereact/inputtextarea';
import { v4 as uuidv4 } from 'uuid';
import { isGranted } from '../../../../utils/abpUtility';

export const SermonActivity = ({ meeting }) => {
    const queryClient = useQueryClient();
    const [editMode, setEditMode] = useState(false);

    const handleSave = async (text, verses) => {
        await sermonsService.update({
            meetingId: meeting?.id,
            speaker: null,
            text: text,
            sermonQuotes: verses,
            sermonMedias: []
        });

        await queryClient.invalidateQueries('meeting');
        await queryClient.invalidateQueries('verses_download');
        setEditMode(false);
    };

    return <SermonContainer>{editMode ? <UpdateSermonContent onSave={handleSave} meeting={meeting} setEditMode={setEditMode} /> : <SermonPreview meeting={meeting} setEditMode={setEditMode} />}</SermonContainer>;
};

const SermonPreview = ({ meeting, setEditMode }) => {
    const [sermonPreviewStructure, setSermonPreviewStructure] = useState([]);

    useEffect(() => {
        if (meeting?.sermon?.text) {
            try {
                const textJson = JSON.parse(meeting.sermon.text);
                setSermonPreviewStructure(textJson);
            } catch (e) {}
        }
    }, [meeting]);

    const RenderItem = ({ item }) => {
        switch (item.type) {
            case 'text':
                return <SermonText>{item.value}</SermonText>;
            case 'verse':
                return (
                    <SermonVersesPreviewContainer>
                        {item.value?.map((verse) => (
                            <SermonVersePreview key={verse.name}>
                                <h5>
                                    <RenderVerseChapterName text={verse.name} />
                                </h5>
                                <q>{verse.content}</q>
                            </SermonVersePreview>
                        ))}
                    </SermonVersesPreviewContainer>
                );
            default:
                return <></>;
        }
    };

    return (
        <SermonPreviewContainer>
            {isGranted('Pages.Sermon.Edit') && <Button style={{ alignSelf: 'flex-end' }} className="mb-3" icon="pi pi-pencil" label="Edit" onClick={() => setEditMode(true)} />}
            {sermonPreviewStructure.map((i) => (
                <RenderItem key={i.id} item={i} />
            ))}
        </SermonPreviewContainer>
    );
};

const SermonText = styled.p`
    margin: 10px;
    font-size: 15px;
`;

const SermonPreviewContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const UpdateSermonContent = ({ onSave, meeting, setEditMode }) => {
    const [loading, setLoading] = useState(false);
    const [sermonItems, setSermonItems] = useState([]);

    useEffect(() => {
        if (meeting?.sermon?.text) {
            try {
                const textJson = JSON.parse(meeting.sermon.text);
                setSermonItems(textJson);
            } catch (e) {}
        }
    }, [meeting]);

    const addText = (idx) => {
        const newItem = {
            id: uuidv4(),
            type: 'text'
        };
        const newItems = [...sermonItems.slice(0, idx + 1), newItem, ...sermonItems.slice(idx + 1)];
        setSermonItems(newItems);
    };

    const addVerse = (idx) => {
        const newItem = {
            id: uuidv4(),
            type: 'verse'
        };
        const newItems = [...sermonItems.slice(0, idx + 1), newItem, ...sermonItems.slice(idx + 1)];
        setSermonItems(newItems);
    };

    const onValueChange = (id, value) => {
        setSermonItems((prevItems) => {
            return prevItems.map((item) => {
                if (item.id === id) {
                    return { ...item, value: value };
                }
                return item;
            });
        });
    };

    const handleSaveBtnClicked = () => {
        setLoading(true);
        const verses = sermonItems
            .filter((i) => i.type === 'verse')
            .map((i) => i.value)
            .flat();
        onSave(JSON.stringify(sermonItems), verses);
    };

    const removeBlock = (id) => {
        const updateSermonItems = sermonItems.filter((i) => i.id !== id);
        setSermonItems(updateSermonItems);
    };

    const SermonActionsComponent = ({ idx }) => {
        return (
            <SermonActions style={{ opacity: sermonItems.length === 0 ? 100 : 0 }}>
                <Button severity="secondary" label="Add Text" onClick={() => addText(idx)} />
                <Button severity="secondary" label="Add Verse" onClick={() => addVerse(idx)} />
            </SermonActions>
        );
    };

    return (
        <SermonTextContainer>
            <Button style={{ alignSelf: 'flex-end' }} icon="pi pi-times" className="mb-3" severity="danger" label="Close" onClick={() => setEditMode(false)} />
            <SermonActionsComponent idx={-1} />
            {sermonItems.map((sermonItem, idx) => (
                <SermonItem key={sermonItem.id}>
                    <Button severity="secondary" style={{ alignSelf: 'flex-end', position: 'relative', top: '30px', right: '-15px' }} onClick={() => removeBlock(sermonItem.id)} icon="pi pi-times" />
                    <RenderSermonItem sermonItem={sermonItem} onValueChange={onValueChange} />
                    <SermonActionsComponent idx={idx} />
                </SermonItem>
            ))}
            <Button label="Save changes" icon="pi pi-check" disabled={loading} loading={loading} className="w-fit" onClick={handleSaveBtnClicked} />
        </SermonTextContainer>
    );
};

const RenderSermonItem = ({ sermonItem, onValueChange }) => {
    switch (sermonItem.type) {
        case 'text':
            return <RenderTextField sermonItem={sermonItem} onValueChange={onValueChange} />;
        case 'verse':
            return <RenderVersesField sermonItem={sermonItem} onValueChange={onValueChange} />;

        default:
            return null;
    }
};

const RenderVersesField = ({ sermonItem, onValueChange }) => {
    const dialogRef = useRef(null);

    const handleBibleChange = (data) => {
        onValueChange(sermonItem.id, data);
        dialogRef.current.closeDialog();
    };

    return (
        <SermonVerses>
            <BibleDialog ref={dialogRef} onSubmit={handleBibleChange} selectedVerses={sermonItem.value} />
            <Button severity="help" label="Edit verses" onClick={() => dialogRef.current.openDialog()} />
            <SermonVersesPreviewContainer>
                {sermonItem.value?.map((verse) => (
                    <SermonVersePreview key={verse.name}>
                        <h5>
                            <RenderVerseChapterName text={verse.name} />
                        </h5>
                        <q>{verse.content}</q>
                    </SermonVersePreview>
                ))}
            </SermonVersesPreviewContainer>
        </SermonVerses>
    );
};

const RenderTextField = ({ sermonItem, onValueChange }) => {
    return <InputTextarea rows={10} style={{ width: '100%' }} value={sermonItem.value} onChange={(e) => onValueChange(sermonItem.id, e.target.value)} />;
};

const SermonVersesPreviewContainer = styled.div`
    margin: 10px;
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

const SermonVersePreview = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;

    p {
    }

    h5 {
        margin-bottom: 0 !important;
    }
`;

const SermonVerses = styled.div`
    width: 100%;
    min-height: 100px;
    border: 1px solid #d9dadc;
    border-radius: 8px;
    padding: 15px;
`;

const SermonActions = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 150ms ease-in-out;

    .p-button {
        width: 100px;
    }

    &:hover {
        opacity: 100 !important;
    }

    @media (max-width: 768px) {
        opacity: 100;
        flex-direction: column;
    }
`;

const SermonItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const SermonTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const SermonContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
