import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { Password } from "primereact/password";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { InputTextarea } from "primereact/inputtextarea";
import { ErrorMessage } from "@hookform/error-message";
import { FileUpload } from "primereact/fileupload";
import { Dropdown } from "primereact/dropdown";

/**
 * It renders a table with a toolbar, a dialog for editing/creating entities, and a dialog for deleting
 * entities
 */
const CrudTable = ({
  data,
  fieldsToShow,
  loading,
  headerTitle,
  fieldsToEdit,
  fieldsToCreate,
  onEditSubmit,
  setLoading,
  onCreateSubmit,
  onDelete,
  lazy,
  lazyParams,
  setLazyParams,
  globalFilter,
  setGlobalFilter,
  primaryKey,
  getSingleEntity,
  canEdit,
  canCreate,
  canDelete,
  extendBody: ExtendBody,
  extendHeader: ExtendHeader,
}) => {
  const [entities, setEntities] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [entityEditDialog, setEntityEditDialog] = useState(false);
  const [managedEntity, setManagedEntity] = useState({});
  const [managmentState, setManagmentState] = useState("create");
  const [fields, setFields] = useState(fieldsToCreate);
  const [deleteEntityDialog, setDeleteEntityDialog] = useState(false);
  const [deleteEntitiesDialog, setDeleteEntitiesDialog] = useState(false);

  const {
    register: registerEdit,
    handleSubmit: handleEditSubmit,
    reset: resetEditForm,
    control,
    formState: { errors },
  } = useForm();

  const [submitted, setSubmitted] = useState(false);

  const toast = useRef(null);
  const dt = useRef(null);

  useEffect(() => {
    setEntities(data);
  }, [data]);

  useEffect(() => {
    resetEditForm(managedEntity);
  }, [managedEntity, resetEditForm]);

  const openNew = () => {
    setManagedEntity({});
    setManagmentState("create");
    setFields(fieldsToCreate);
    setSubmitted(false);
    setEntityEditDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setEntityEditDialog(false);
  };

  const hideDeleteEntityDialog = () => {
    setDeleteEntityDialog(false);
  };

  const hideDeleteEntitiesDialog = () => {
    setDeleteEntitiesDialog(false);
  };

  const editEntity = (ent) => {
    setLoading(true);
    var keyData = ent[primaryKey];

    if (!keyData) {
      return;
    }

    getSingleEntity(ent[primaryKey]).then((res) => {
      if (!res) {
        return;
      }
      setManagedEntity({ ...res });
      setManagmentState("edit");
      setFields(fieldsToEdit);
      setEntityEditDialog(true);
      setLoading(false);
    });
  };

  const confirmDeleteEntity = (ent) => {
    setManagedEntity(ent);
    setDeleteEntityDialog(true);
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const confirmDeleteSelected = () => {
    setDeleteEntitiesDialog(true);
  };

  const deleteSelectedProducts = () => {
    let _products = entities.filter((val) => !selectedProducts.includes(val));
    setEntities(_products);
    setDeleteEntitiesDialog(false);
    setSelectedProducts(null);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Products Deleted",
      life: 3000,
    });
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          {canCreate && (
            <Button
              label="New"
              icon="pi pi-plus"
              className="p-button-success mr-2"
              onClick={openNew}
            />
          )}
          {canDelete && (
            <Button
              label="Delete"
              icon="pi pi-trash"
              className="p-button-danger"
              onClick={confirmDeleteSelected}
              disabled={!selectedProducts || !selectedProducts.length}
            />
          )}
          <ExtendHeader data={data} />
        </div>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="Export"
          icon="pi pi-upload"
          className="p-button-help"
          onClick={exportCSV}
        />
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        {canEdit && (
          <Button
            icon="pi pi-pencil"
            className="p-button-rounded p-button-success mr-2"
            onClick={() => editEntity(rowData)}
          />
        )}
        {canDelete && (
          <Button
            icon="pi pi-trash"
            className="p-button-rounded p-button-warning mt-2"
            onClick={() => confirmDeleteEntity(rowData)}
          />
        )}
        <ExtendBody data={rowData} />
      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">{headerTitle}</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </span>
    </div>
  );

  const deleteEntity = () => {
    setLoading(true);
    onDelete(managedEntity);
    setDeleteEntityDialog(false);
  };

  const editDialogFooter = () => (
    <div style={{ width: "fit-content" }}>
      <Button
        label="Save"
        icon="pi pi-check"
        type="submit"
        className="p-button"
      />
    </div>
  );
  const deleteEntityDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteEntityDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteEntity}
      />
    </>
  );
  const deleteProductsDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteEntitiesDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteSelectedProducts}
      />
    </>
  );

  const renderEditField = (type, name, rules, props) => {
    switch (type) {
      case "string":
        return (
          <>
            <InputText
              className={classNames({
                "p-invalid": errors[name],
              })}
              {...registerEdit(name, { ...rules })}
            />
            <ErrorMessage
              errors={errors}
              name={name}
              render={({ message }) => (
                <p className="error-message">{message ?? null}</p>
              )}
            />
          </>
        );
      case "number":
        return (
          <>
            <Controller
              name={name}
              control={control}
              defaultValue={0}
              rules={{ ...rules }}
              render={({ field }) => (
                <InputNumber
                  {...field}
                  inputId="integeronly"
                  value={field.value}
                  onValueChange={(e) => field.onChange(e.value)}
                  className={classNames({
                    "p-invalid": errors[name],
                  })}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name={name}
              render={({ message }) => (
                <p className="error-message">{message ?? null}</p>
              )}
            />
          </>
        );
      case "dropdown":
        return (
          <>
            <Controller
              name={name}
              control={control}
              rules={{ ...rules }}
              render={({ field }) => <Dropdown {...props} {...field} />}
            ></Controller>

            <ErrorMessage
              errors={errors}
              name={name}
              render={({ message }) => (
                <p className="error-message">{message ?? null}</p>
              )}
            />
          </>
        );
      case "textarea":
        return (
          <>
            <InputTextarea
              rows={5}
              className={classNames({
                "p-invalid": errors[name],
              })}
              {...registerEdit(name, { ...rules })}
            />

            <ErrorMessage
              errors={errors}
              name={name}
              render={({ message }) => (
                <p className="error-message">{message ?? null}</p>
              )}
            />
          </>
        );
      case "bool":
        return (
          <>
            <Controller
              name={name}
              control={control}
              rules={{ ...rules }}
              render={({ field }) => (
                <InputSwitch {...field} checked={field.value} />
              )}
            ></Controller>

            <ErrorMessage
              errors={errors}
              name={name}
              render={({ message }) => (
                <p className="error-message">{message ?? null}</p>
              )}
            />
          </>
        );
      case "password":
        return (
          <>
            <Controller
              name={name}
              rules={{ ...rules }}
              control={control}
              render={({ field }) => <Password {...field} />}
            ></Controller>

            <ErrorMessage
              errors={errors}
              name={name}
              render={({ message }) => (
                <p className="error-message">{message ?? null}</p>
              )}
            />
          </>
        );
      case "image":
        return (
          <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field }) => (
              <FileUpload
                accept="image/*, .json,application/json"
                maxFileSize={100000000}
                mode="advanced"
                onSelect={(event) => field.onChange(event.files[0])}
              />
            )}
          ></Controller>
        );
      case "video":
        return (
          <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field }) => (
              <FileUpload
                accept="video/*"
                maxFileSize={10000000000}
                mode="advanced"
                onSelect={(event) => field.onChange(event.files[0])}
              />
            )}
          ></Controller>
        );
      case "calendar":
        return (
          <>
            <Controller
              name={name}
              control={control}
              rules={rules}
              render={({ field }) => (
                <Calendar
                  {...field}
                  value={new Date(field.value)}
                  dateFormat="dd.mm.yy"
                  onChange={(e) =>
                    field.onChange(new Date(e.value).toISOString())
                  }
                />
              )}
            ></Controller>
            <ErrorMessage
              errors={errors}
              name={name}
              render={({ message }) => (
                <p className="error-message">{message ?? null}</p>
              )}
            />
          </>
        );
      default:
    }
  };

  const onEditBaseSubmit = (data) => {
    setLoading(true);
    setEntityEditDialog(false);
    var submitFnc = managmentState === "edit" ? onEditSubmit : onCreateSubmit;
    submitFnc(data);
  };

  const onPage = (event) => {
    setLazyParams({ ...lazyParams, ...event });
  };

  const externalDataTableParams = lazy
    ? {
        first: lazyParams.first,
        page: lazyParams.page,
        rows: lazyParams.rows,
        totalRecords: lazyParams.totalRecords,
        onPage: onPage,
      }
    : {
        rows: 5,
        first: 0,
      };

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>

          <DataTable
            ref={dt}
            loading={loading}
            value={entities}
            selection={selectedProducts}
            onSelectionChange={(e) => {
              setSelectedProducts(e.value);
            }}
            dataKey="id"
            paginator
            rowsPerPageOptions={[5, 10, 25]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
            globalFilter={globalFilter}
            emptyMessage="No products found."
            header={header}
            responsiveLayout="scroll"
            lazy={lazy}
            {...externalDataTableParams}
          >
            <Column
              selectionMode="multiple"
              headerStyle={{ width: "3rem" }}
            ></Column>
            {fieldsToShow.map((field) => (
              <Column
                key={field.name + field.header}
                field={field.name}
                header={field.header}
                body={field.body}
              />
            ))}
            <Column body={actionBodyTemplate}></Column>
          </DataTable>

          <Dialog
            visible={entityEditDialog}
            style={{ width: "650px" }}
            header="Details"
            modal
            className="p-fluid"
            onHide={hideDialog}
          >
            <form onSubmit={handleEditSubmit(onEditBaseSubmit)}>
              {fields.map((field) => (
                <div
                  className="field"
                  key={field.name + field.type + field.title}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label htmlFor="name">{field.title}</label>

                  {field.body ? (
                    <>
                      {field.body({
                        register: registerEdit,
                        control,
                        name: field.name,
                        errors,
                        rules: field.rules,
                        data: managedEntity,
                      })}
                      <ErrorMessage
                        errors={errors}
                        name={field.name}
                        render={({ message }) => (
                          <p className="error-message">{message ?? null}</p>
                        )}
                      />
                    </>
                  ) : (
                    renderEditField(
                      field.type,
                      field.name,
                      field.rules,
                      field.props
                    )
                  )}
                </div>
              ))}
              {editDialogFooter()}
            </form>
          </Dialog>

          <Dialog
            visible={deleteEntityDialog}
            style={{ width: "450px" }}
            header="Confirm"
            modal
            footer={deleteEntityDialogFooter}
            onHide={hideDeleteEntityDialog}
          >
            <div className="flex align-items-center justify-content-center">
              <i
                className="pi pi-exclamation-triangle mr-3"
                style={{ fontSize: "2rem" }}
              />
              {managedEntity && (
                <span>Are you sure you want to delete this entity?</span>
              )}
            </div>
          </Dialog>

          <Dialog
            visible={deleteEntitiesDialog}
            style={{ width: "450px" }}
            header="Confirm"
            modal
            footer={deleteProductsDialogFooter}
            onHide={hideDeleteEntitiesDialog}
          >
            <div className="flex align-items-center justify-content-center">
              <i
                className="pi pi-exclamation-triangle mr-3"
                style={{ fontSize: "2rem" }}
              />
              {selectedProducts && (
                <span>
                  Are you sure you want to delete the selected products?
                </span>
              )}
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

CrudTable.defaultProps = {
  data: [],
  fieldsToShow: [],
  fieldsToCreate: [],
  fieldsToEdit: [],
  loading: true,
  headerTitle: "Manage Entities",
  onEditSubmit: () => {},
  onCreateSubmit: () => {},
  onDelete: () => {},
  setLoading: () => {},
  lazy: false,
  setLazyParams: () => {},
  lazyParams: {
    rows: 5,
    first: 0,
    page: 1,
  },
  globalFilter: "",
  setGlobalFilter: () => {},
  primaryKey: "id",
  getSingleEntity: () => {
    return {};
  },
  canEdit: true,
  canDelete: true,
  canCreate: true,
  extendBody: () => null,
  extendHeader: () => null,
};

CrudTable.propTypes = {};

export default CrudTable;
