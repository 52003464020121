import React from 'react';

const Dashboard = () => {
    const playlist = [1, 2, 3, 4, 5];

    return (
        <div className="layout-dashboard">
            <div className="grid justify-content-center">
                <div className="lg:col-4 md:col-12 sm:col-12 mb-5">
                    <div className="card text-center">
                        <div className="card-header">
                            <div className="card-title">
                                <h6>График</h6>
                            </div>
                        </div>
                        <div className="card-body">
                            <iframe
                                src="https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%23ffffff&ctz=Europe%2FSofia&mode=AGENDA&showTitle=0&showNav=1&showDate=0&showPrint=0&showCalendars=0&showTz=0&src=Y2h1cmNoLnNhaW50LmpvaG5AZ21haWwuY29t&color=%23039BE5&key=AIzaSyBgovKe42FB4B77zjeTX_v_7NOBu9_1rTs"
                                style={{ borderWidth: 0 }}
                                width={320}
                                height={365}
                                title="Calendar"
                            ></iframe>
                        </div>
                    </div>
                </div>
                <div className="lg:col-8 md:col-12 sm:col-12 mb-5">
                    <div className="card text-center">
                        <div className="card-header">
                            <div className="card-title">
                                <h6>Последни 5 проповеди</h6>
                            </div>
                        </div>
                        <div className="card-body">
                            {playlist.map((index) => (
                                <iframe
                                    key={index}
                                    width="320"
                                    height="180"
                                    src={`https://www.youtube.com/embed?listType=playlist&list=PLSMfFnrfOPYGDxxtP3gqOKrKSD5VkxJt4&index=${index}`}
                                    title="YouTube video player"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen
                                    className='m-2'
                                ></iframe>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
