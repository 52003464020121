import http from '../http/httpService';

class FilesService {
    async getAll(params) {
        const result = await http.get('api/services/app/Files/GetAll', { params });
        return result.data.result;
    }

    async getPCloudThumbnails(params) {
        const result = await http.get('api/services/app/Files/PCloudGetThumbnails', { params });
        return result.data.result;
    }

    async getPCloudThumbnail(params) {
        const result = await http.get('api/services/app/Files/PCloudGetThumbnail', { params });
        return result.data.result;
    }

    async receivePCloudFile(params) {
        const result = await http.get('api/services/app/Files/PCloudReceiveFile', { params });
        return result.data.result;
    }

    async getPCloudFile(fileId) {
        const result = await http.get('api/services/app/Files/PCloudGetFile', { params: { fileId } });
        return result.data.result;
    }

    async getVerses(params) {
        const result = await http.get('api/services/app/Files/GetVerses', { params });
        return result.data.result;
    }

    async pCloudDeleteFile(fileId) {
        const result = await http.delete('api/services/app/Files/PCloudDeleteFile', { params: { fileId } });
        return result.data.result;
    }

    async pCloudUploadMeetingFile(file, meetingId, fileName) {
        const name = `${meetingId}_${fileName}`;

        const formData = new FormData();
        formData.append('file', file, name);

        const result = await http.post('api/services/app/Files/PCloudUploadFile', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return result.data.result;
    }

    async pCloudUploadFile(file) {
        const formData = new FormData();
        formData.append('file', file);

        const result = await http.post('api/services/app/Files/PCloudUploadFile', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return result.data.result;
    }
}

export const getFileName = (fileName) => {
    return fileName?.includes('_') ? fileName?.split('_')?.[1] : fileName;
};

export default new FilesService();
