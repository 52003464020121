import { useContext } from 'react';
import { NotificationContext } from '../../context/notificationContext';

const useNotifications = () => {
    const context = useContext(NotificationContext);

    return context;
};

export { useNotifications };
