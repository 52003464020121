class PersmissionChecker {
    isAdmin(authContext) {
        return authContext?.userRoles?.some((r) => r.normalizedName === 'ADMIN');
    }

    canReadPages(authContext) {
        return authContext?.userRoles?.some((r) => r.grantedPermissions.some(gp => gp === 'Pages.Read'));
    }

    canWritePages(authContext) {
        return authContext?.userRoles?.some((r) => r.grantedPermissions.some(gp => gp === 'Pages.Write'));
    }
}

export default new PersmissionChecker();
