const AppConsts = {
  userManagement: {
    defaultAdminUserName: "admin",
  },
  localization: {
    defaultLocalizationSourceName: "ChurchSaintJohn",
  },
  authorization: {
    encrptedAuthTokenName: "enc_auth_token",
  },
  remoteServiceBaseUrl: process.env.REACT_APP_REMOTE_SERVICE_BASE_URL,
};
export default AppConsts;
