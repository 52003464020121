import { Outlet } from 'react-router-dom';
import App from '../../App';

const MainLayout = ({ colorScheme, setColorScheme }) => {
    return (
        <div>
            <App colorScheme={colorScheme} setColorScheme={setColorScheme}>
                <Outlet />
            </App>
        </div>
    );
};

export default MainLayout;
