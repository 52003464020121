import http from '../http/httpService';

class BibleService {
    async getBooks() {
        let result = await http.get('api/services/app/MeetingSermonReferenceData/GetBibleHeads');
        return result.data.result;
    }

    async getChapters(head) {
        let result = await http.get('api/services/app/MeetingSermonReferenceData/GetBibleChapters', {
            params: {
                head
            }
        });
        return result.data.result;
    }

    async getVerses(chapter, from, to) {
        let result = await http.get('api/services/app/MeetingSermonReferenceData/GetBibleVerses', {
            params: {
                chapter,
                from,
                to
            }
        });
        return result.data.result;
    }
}

export default new BibleService();
