import React, { useContext, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Error } from './pages/Error';
import { NotFound } from './pages/NotFound';
import { Access } from './pages/Access';
import MainLayout from './components/layouts/MainLayout';
import Dashboard from './components/Dashboard';
import Users from './pages/Users/Users';
import Roles from './pages/Roles/Roles';
import Pages from './pages/Pages/Pages';
import EditPage from './pages/Pages/edit';
import { AuthContext } from './components/configuration/InitialConfigurationWrapper';
import pc from './utils/permissionChecker';
import { MeetingCreate, MeetingDetails } from './pages/Meetings';
import ProtectedRoute from './components/routes/ProtectedRoutes';

export const ctx = React.createContext();

const AppWrapper = (props) => {
    let location = useLocation();
    const authContext = useContext(AuthContext);
    const [colorScheme, setColorScheme] = useState('light');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <Routes>
            <Route path="/error" element={<Error colorScheme={colorScheme} />} />
            <Route path="/login" element={<Navigate to={'/'} />} />
            <Route path="/notfound" element={<NotFound colorScheme={colorScheme} />} />
            <Route path="/access" element={<Access colorScheme={colorScheme} />} />

            <Route path="/" element={<MainLayout colorScheme={colorScheme} setColorScheme={setColorScheme} />}>
                <Route path="/" exact="true" element={<Dashboard />} />
                <Route path="users" element={withAuth(<Users />, 'Pages.Users')} />
                <Route path="roles" element={withAuth(<Roles />, 'Pages.Roles')} />

                <Route path="pages" element={withAuth(<Pages />, 'Pages.Read')} />

                <Route path="pages/:id" element={withAuth(<EditPage />, 'Pages.Read')} />
                <Route path="meetings/create" element={withAuth(<MeetingCreate />, 'Pages.Meeting.Create')} />
                <Route path="meetings/:meetingId/details" element={withAuth(<MeetingDetails />, 'Pages.Meeting')} />
            </Route>
            <Route path="*" element={<NotFound />}></Route>
        </Routes>
    );
};

export default AppWrapper;

const withAuth = (component, permission) => {
    return <ProtectedRoute permission={permission}>{component}</ProtectedRoute>;
};
