import React, { createContext, useEffect, useMemo, useState } from 'react';
import tenantService from '../../services/tenants/tenantsService';
import abpUserConfigurationService from '../../services/configurations/abpUserConfigurationService';
import authenticationService from '../../services/authentication/authenticationService';
import Utils from '../../utils/utils';
import Loading from '../loading/Loading';
import UnAuthAppWrapper from '../../UnAuthAppWrapper';
import AppWrapper from '../../AppWrapper';
import { GoogleOAuthProvider } from '@react-oauth/google';
import activitiesService from '../../services/activities/activitiesService';
import { useQuery } from 'react-query';
import meetingsService from '../../services/meetings/meetingsService';
import { NotificationWrapper } from '../../context/notificationContext';

export const AuthContext = createContext(null);
export const MeetingsContext = createContext(null);
export const ReferenceDataContext = createContext({});
const abp = window.abp;

const useLatestMeeting = () => {
    const { data } = useQuery({
        queryKey: ['latestMeeting'],
        queryFn: meetingsService.getCurrentMeeting,
        enabled: authenticationService.isAuthenticated()
    });
    return { data };
};

const ConfigurationWrapper = () => {
    const [loading, setLoading] = useState(true);
    const [authProviders, setAuthProviders] = useState({});
    const { data: latestMeeting } = useLatestMeeting();
    const [activities, setActivities] = useState([]);
    const [auth, setAuth] = useState(false);
    const [user, setUser] = useState({});
    const [userRoles, setUserRoles] = useState([]);

    const authContext = useMemo(() => {
        return { authProviders, user, userRoles };
    }, [authProviders, user, userRoles]);

    let referenceData = useMemo(() => {
        return {
            activities
        };
    }, [activities]);

    let meetingContextData = useMemo(() => {
        return {
            latestMeeting
        };
    }, [latestMeeting]);

    const showError = () => {};

    useEffect(() => {
        getAllData({ setLoading, showError, setUser, setUserRoles, setAuthProviders, setActivities });
        getActivities(setActivities);

        if (abp.auth.getToken()) {
            setAuth(true);
        } else {
            setAuth(false);
        }
    }, []);

    if (loading) {
        return <Loading />;
    }

    return (
        <AuthContext.Provider value={authContext}>
            <NotificationWrapper>
                <MeetingsContext.Provider value={meetingContextData}>
                    <ReferenceDataContext.Provider value={referenceData}>
                        <GoogleOAuthProvider clientId={authProviders?.find((ap) => ap.name === 'Google')?.clientId}>{auth === false ? <UnAuthAppWrapper /> : <AppWrapper />}</GoogleOAuthProvider>
                    </ReferenceDataContext.Provider>
                </MeetingsContext.Provider>
            </NotificationWrapper>
        </AuthContext.Provider>
    );
};

const getActivities = (func) => {
    activitiesService
        .getAll()
        .then((res) => {
            func(res);
        })
        .catch(() => {});
};

/**
 * It gets the tenant id from the tenant name, sets the tenant id cookie, gets the user configuration,
 * sets the user if authenticated, and sets the loading to false
 */
const getAllData = ({ setLoading, showError, setUser, setUserRoles, setAuthProviders, setActvities }) => {
    let tenantName = process.env.REACT_APP_TENANT_NAME ?? '';

    tenantService
        .getIdByTenantName({ tenancyName: tenantName })
        .then((response) => {
            if (!response?.tenantId) {
                showError('There is no tenant with that name');
            }
            abp.multiTenancy.setTenantIdCookie(response?.tenantId);
        })
        .catch(() => {
            showError();
        });

    abpUserConfigurationService
        .getAll()
        .then((data) => {
            Utils.extend(true, abp, data.data.result);
            abp.clock.provider = Utils.getCurrentClockProvider(data.data.result.clock.provider);

            authenticationService
                .getExternalAuthenticationProviders()
                .then((externalProviders) => {
                    setAuthProviders(externalProviders);

                    // get user if auth
                    if (authenticationService.isAuthenticated()) {
                        authenticationService.getCurrentLoginInfo().then((res) => {
                            setUser(res.user);
                            setUserRoles(res.userRoles);
                            setLoading(false);
                        });
                    } else {
                        setLoading(false);
                    }
                })
                .catch(() => {
                    showError('An error occured while fetching the user information');
                });
        })
        .catch(() => {
            showError('An error occured while fetching the user information');
        });
};

export default ConfigurationWrapper;
